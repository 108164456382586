<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>메뉴관리</h1>
      <div class="is-right">
      </div>
    </div>

    <!--조회-->
    <div class="jh-search-form">
      <table>
        <colgroup>
            <col width="55px">
            <col width="220px">
            <col width="70px">
            <col width="100px">
            <col>
        </colgroup>
        <tr>
          <th><label>메뉴그룹</label></th>
          <td>
            <v-select class="jh-form" :items="menuGroup1" item-text="CD_NM" item-value="CD" v-on:change="clickMenuGroupItem" v-model="menuGroup"></v-select>
          </td>
          <th><label>표시여부</label></th>
          <td>
            <v-select class="jh-form" :items="useYn1" item-text="CD_NM" item-value="CD" v-on:change="clickMenuGroupItem" v-model="useYn"></v-select>
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" @click="clickMenuGroupItem">조회</v-btn></td>
        </tr>
      </table>
    </div>
    <!--//조회-->

    <div class="jh-cols">
      <!--메뉴트리-->
      <div class="jh-form-wrap" style="max-width: 400px;">
        <div class="jh-ui-header">
          <h2>메뉴</h2>
          <div class="is-right">
            <v-btn class="jh-btn" @click="clickAddMenuGroup" v-if="this.mixin_set_btn(this.$options.name, 'btnAddMenuGroup')">메뉴그룹추가</v-btn>
          </div>
        </div>
        <div class="jh-tree" style="height: 470px;">
          <div class="jh-tree-header">
              <div class="is-left">
                <v-btn class="jh-btn is-sm" @click="unfoldTreeview" v-if="this.mixin_set_btn(this.$options.name, 'btnUnfold')">전체 펼치기</v-btn>
                <v-btn class="jh-btn is-sm" @click="foldTreeview" v-if="this.mixin_set_btn(this.$options.name, 'btnFold')">전체 접기</v-btn>
              </div>
              <div class="is-right">
              </div>
          </div>
          <div class="jh-tree-body">
            <v-treeview class="jh-tree-view" ref="treeview1" :items="treeItems" activatable open-on return-object color="secondary" @update:active="clickMenuTreeItem">
              <template v-slot:prepend="{ open, item }">
                <v-icon v-if="item.children">
                  {{open ? 'jh-icon-tree-folder-open' : 'jh-icon-tree-folder'}}
                </v-icon>
                <v-icon v-else>
                  {{ 'jh-icon-tree-file' }}
                </v-icon>
              </template>
              <template v-slot:label="{ item }">
                {{ item.name }}
                <v-icon>
                  {{ item.locked ? 'jh-icon-close-xs' : ''}}
                </v-icon>
              </template>
            </v-treeview>
          </div>
        </div>
      </div>
      <!--//메뉴트리-->

      <!--메뉴상세-->
      <div class="jh-form-wrap">
        <div class="jh-ui-header">
          <h2>메뉴상세</h2>
          <div class="is-right">
            <v-btn class="jh-btn" @click="clickAddMenu" v-if="this.mixin_set_btn(this.$options.name, 'btnAddMenu')">메뉴추가</v-btn>
            <v-btn class="jh-btn is-del" @click="clickDeleteMenu" v-if="this.mixin_set_btn(this.$options.name, 'btnDelMenu')">메뉴삭제</v-btn>
            <v-btn class="jh-btn is-main" @click="clickSaveMenu" v-if="this.mixin_set_btn(this.$options.name, 'btnSaveMenu')">메뉴저장</v-btn>
          </div>
        </div>
        <v-form ref="form" lazy-validation>
          <table class="jh-tbl-detail">
            <colgroup>
                <col width="150px">
                <col>
            </colgroup>
            <tbody>
              <tr>
                <th><label>메뉴아이디</label></th>
                <td>
                  <v-text-field class="jh-form is-md" style="width: 220px" :disabled="menuDetailDis.id" v-model="menuDetail.id"></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label>상위메뉴아이디</label></th>
                <td>
                  <v-text-field class="jh-form is-md" style="width: 220px" :disabled="menuDetailDis.parentId" v-model="menuDetail.parentId"></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label class="is-required">메뉴아이디</label></th>
                <td>
                  <v-text-field class="jh-form is-md" style="width: 220px" :disabled="menuDetailDis.nodeId" v-model="menuDetail.nodeId" :rules="validateRules.nodeId" required placeholder="상위메뉴아이디 + 2자리 숫자"></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label class="is-required">메뉴명</label></th>
                <td>
                  <v-text-field class="jh-form is-md" style="width: 220px" :disabled="menuDetailDis.name" v-model="menuDetail.name" :rules="validateRules.menuNm" required placeholder="메뉴명"></v-text-field>                
                </td>
              </tr>
              <tr>
                <th><label>프로그램 경로</label></th>
                <td>
                  <div class="jh-cols">
                    <v-text-field class="jh-form is-md" :disabled="menuDetailDis.url" v-model="menuDetail.url" placeholder="프로그램 경로"></v-text-field>
                    <!-- <div class="is-col-fix" v-if="this.mixin_set_btn(this.$options.name, 'btnFindPath')">
                      <v-btn class="jh-btn is-fill">찾기</v-btn>
                    </div>                   -->
                  </div>
                </td>
              </tr>
              <tr>
                <th><label>프로그램 파라메터</label></th>
                <td>
                  <v-text-field class="jh-form is-md" :disabled="menuDetailDis.param" v-model="menuDetail.param" placeholder="프로그램 파라메터"></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label class="is-required">뷰타겟</label></th>
                <td>
                  <v-select class="jh-form is-md" style="width: 220px" :items="viewTarget1" item-text="CD_NM" item-value="CD" placeholder="선택하세요"
                  v-on:change="clickViewTargetItem" v-model="menuDetail.viewTarget" :disabled="menuDetailDis.viewTarget" :rules="validateRules.viewTarget" required
                  ></v-select>
                </td>
              </tr>
              <tr>
                <th><label>팝업 가로사이즈</label></th>
                <td>
                  <v-text-field class="jh-form is-md" style="width: 220px" type="number" value="0" :disabled="menuDetailDis.popupWidth" v-model="menuDetail.popupWidth"></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label>팝업 세로사이즈</label></th>
                <td>
                  <v-text-field class="jh-form is-md" style="width: 220px" type="number" value="0" :disabled="menuDetailDis.popupHeight" v-model="menuDetail.popupHeight"></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label>표시여부</label></th>
                <td>
                  <v-select class="jh-form is-md" style="width: 220px" :items="use2Yn1" item-text="CD_NM" item-value="CD" placeholder="선택하세요"
                  v-model="menuDetail.useYn" :disabled="menuDetailDis.useYn" :rules="validateRules.useYn" required
                  ></v-select>
                </td>
              </tr>
              <tr>
                <th><label class="is-required">정렬순서</label></th>
                <td>
                  <v-text-field class="jh-form is-md" style="width: 220px" type="number" value="0" :disabled="menuDetailDis.sort" v-model="menuDetail.sort" :rules="validateRules.sort" required></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label>메뉴설명</label></th>
                <td>
                  <v-textarea class="jh-form" style="height:80px" noResize placeholder="메뉴설명을 입력하세요." :disabled="menuDetailDis.memo" v-model="menuDetail.memo"></v-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </v-form>
      </div>
      <!--//메뉴상세-->

      <!--버튼-->
      <div class="jh-form-wrap" style="max-width: 400px;">
        <div class="jh-ui-header">
          <h2>버튼목록</h2>
          <div class="is-right">
          </div>  
        </div>        
        <v-data-table
          class="jh-grid"
          height="320"
          :headers="gridDataHeaders"
          :items="gridDataList"
          :items-per-page="30"
          :page.sync="page"
          calculate-widths
          @page-count="pageCount = $event"
          item-key="index"
          fixed-header
          single-select
          hide-default-footer
          v-model="btnListData"
          @click:row="clickBtnListRow"
        >
        </v-data-table>

        <div class="jh-ui-header is-mt-10">
          <h2>버튼상세</h2>
          <div class="is-right">
            <v-btn class="jh-btn" @click="clickAddBtn" v-if="this.mixin_set_btn(this.$options.name, 'btnAddButton')">버튼추가</v-btn>
            <v-btn class="jh-btn is-del" @click="clickDeleteBtn" v-if="this.mixin_set_btn(this.$options.name, 'btnDelButton')">버튼삭제</v-btn>
            <v-btn class="jh-btn is-main" @click="clickSaveBtn" v-if="this.mixin_set_btn(this.$options.name, 'btnSaveButton')">버튼저장</v-btn>
          </div>  
        </div> 
        <v-form ref="form2" lazy-validation>
          <table class="jh-tbl-detail">
            <colgroup>
                <col width="120px">
                <col>
            </colgroup>
            <tbody>
              <!-- <tr>
                <th><label>메뉴아이디</label></th>
                <td>
                  <v-text-field class="jh-form is-md" :disabled="btnDetailDis.menuId" v-model="btnDetailDis.menuId"></v-text-field>
                </td>
              </tr> -->
              <tr>
                <th><label class="is-required">버튼타입</label></th>
                <td>
                  <v-select class="jh-form is-md" :items="btnType1" item-text="CD_NM" item-value="CD" return-object 
                  v-model="btnListDetail.btnType" :disabled="btnDetailDis.btnType" :filled="btnDetailDis.btnType" :rules="validateRules.btnType" required @change="clickBtnDetailTypeItem"
                  ></v-select>
                </td>
              </tr>
              <tr>
                <th><label class="is-required">버튼ID</label></th>
                <td>
                  <v-text-field class="jh-form is-md" :disabled="btnDetailDis.btnId" :filled="btnDetailDis.btnId" v-model="btnListDetail.scrId" :rules="validateRules.btnType" required></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label class="is-required">버튼명</label></th>
                <td>
                  <v-text-field class="jh-form is-md" :disabled="btnDetailDis.btnNm" :filled="btnDetailDis.btnNm" v-model="btnListDetail.scrNm" :rules="validateRules.btnType" required></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </v-form>
      </div>
      <!--//버튼-->

    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil";
import menuGroupList from "@/layouts/default/MenuGroupList";
import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_M810702", //name은 'MENU_' + 파일명 조합
  components: {

  },
  mixins: [mixin],

  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      selectedTreeItems: [],

      HEADERResult  : '',
      DATAResult    : '',

      HEADER_SERVICE: 'setting.system.menu-manage.tr',
      HEADER_TYPE   : 'BIZ_SERVICE',

      URLData    : '',
      headers    : {},
      sendData   : {},

      menuGroup  : '',
      useYn      : '',
      use2Yn     : '',
      viewTarget : '',
      btnType    : {},

      menuDetail    : {
        id          : '',
        parentId    : '',
        nodeId      : '',
        name        : '',
        url         : '',
        param       : '',
        viewTarget  : '',
        popupWidth  : '',
        popupHeight : '',
        useYn       : '',
        sort        : '',
        memo        : '',
        MENU_DIV_CD : '',
      },

      menuDetailNodeType : '',

      menuDetailDis : {
        id          : true,
        parentId    : true,
        nodeId      : true,
        name        : true,
        url         : true,
        param       : true,
        viewTarget  : true,
        popupWidth  : true,
        popupHeight : true,
        useYn       : true,
        sort        : true,
        memo        : true,

      },

      btnListDetail     : {
        scrNm     : '',
        scrId     : '',
        btnType   : '',
        btnId     : '',
        menuId    : '',
        btnTypeNm : '',
      },

      btnTypeList : {

      },

      btnDetailDis : {
        menuId  : true,
        btnType : true,
        btnId   : true,
        btnNm   : true
      },

      menuGroup1 : [],
      useYn1     : [],
      viewTarget1: [],
      use2Yn1    : [],
      btnType1   : [],

      treeItems  : [],

      gridDataHeaders: [
        {
          text    : "No",
          align   : "center",
          value   : "index",
          width   : "60px",
          sortable: true,
        },
        {
          text    : "버튼ID",
          value   : "SCR_BTN_ID",
          align   : "center",
          sortable: true
        },
        {
          text    : "버튼명",
          value   : "SCR_BTN_NM",
          align   : "center",
          sortable: true,
        },
      ],
      gridDataList : [],
      btnListData  : [],

      alertMsg: {
        succ       : "정상적으로 처리되었습니다.",
        succDel    : "정상적으로 삭제되었습니다.",
        err        : "시스템 장애가 일어났습니다.<br>관리자에게 문의하세요.",
        comChk     : "회사구분란에서 회사를 선택 후 이용해주십시오.",
        cusChk     : "고객상세정보를 확인 후 이용해주십시오.",
        delChk     : "삭제 하시겠습니까?",
        modChk     : "저장 하시겠습니까?",
        menuDelChk : "해당메뉴 권한이 모두 삭제됩니다.<br>계속하시겠습니까?",
        zeroDelChk : "체크된 항목이 존재하지 않습니다.",
        overDelChk : "하나의 항목만 삭제할 수 있습니다.",
        menuLinkChk: "메뉴가 링크되어있어 하위로 추가가 불가합니다!",
        btnDelChk  : "선택된 항목이 존재하지 않습니다.",
        btnAddChk  : "버튼을 추가할 수 없습니다!<br>메뉴를 선택해주시기 바랍니다."
      },

      valid: true,
      validateRules:{
        menuNm: [
          v => !!v || '메뉴명 은(는) 필수입력입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        nodeId: [
          v => !!v || '메뉴ID 은(는) 필수입력입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        viewTarget: [
          v => !!v || '뷰타겟 은(는) 필수입력입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        useYn: [
          v => !!v || '사용여부 은(는) 필수입력입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        sort: [
          v => !!v || '정렬번호는 필수입니다.',
          //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 100 ) || '0보다 작은 숫자는 입력할 수 없습니다.',
        ],

        btnType: [
          v => !!v || '버튼타입 은(는) 필수입력입니다.',
          //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
        ],
        btnId: [
          v => !!v || '버튼ID 은(는) 필수입력입니다.',
          //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
        ],
        btnNm: [
          v => !!v || '버튼명 은(는) 필수입력입니다.',
          //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
        ],

        cd: [
          v => !!v || '코드는 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        cdNm: [
          v => !!v || '공통코드명은 필수입니다.',
        ],
        cdUseFrDt: [
          v => !!v || '사용시작일은 필수입니다.',
          v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
        ],
        cdUseToDt: [
          v => !!v || '사용종료일은 필수입니다.',
          v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
        ],
        cdType: [
          v => !!v || '코드구분은 필수입니다.',
        ],
        sortOrd: [
          //v => !!v || '정렬번호는 필수입니다.',
          v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
        ],
      },

      tempTreeItems : () => {
        let tempData = [];
        tempData.push(this.selectedTreeItems);
        return tempData;
      },
    };
  },

  methods: {
    unfoldTreeview() {
      this.$refs.treeview1.updateAll(true);
    },
    foldTreeview() {
      this.$refs.treeview1.updateAll(false);
    },

    getMenuGroup() {
      this.URLData = '';
      this.URLData = "/api/setting/system/menu-manage/group/inqire";

      this.headers = {};
      this.headers["SERVICE"] = "setting.system.menu-manage.group";
      this.headers["METHOD"]  = "inqire";
      this.headers["ASYNC"]   = "false";
      this.headers["TYPE"]    = "BIZ_SERVICE";

      this.result()
    },

    getMenuGroupThen(response) {
      let data = response.DATA;
      this.menuGroup1 = data;

      // 화면 열릴 때 메뉴 불러오기
      this.menuGroup = data[0].CD;
      this.clickMenuGroupItem();
    },

    getSelBoxList() {
      this.URLData = '';
      this.URLData = "/api/code/common/code-book/inqry";

      this.headers = {};
      this.headers["SERVICE"] = "code.common.code-book";
      this.headers["METHOD"]  = "inqry";
      this.headers["ASYNC"]   = false;
      this.headers["TYPE"]    = "BIZ_SERVICE";

      this.sendData = {};
      let sendList = [];

      sendList.push({"GROUP_CD": "TWB015"});
      sendList.push({"GROUP_CD": "TWB004"});
      sendList.push({"GROUP_CD": "TWB010"});

      this.sendData["GROUP_CD"] = sendList;
      this.result();
    },

    getSelBoxListThen(response) {
      let data = response;

      this.useYn1.push({CD_NM: "전체", CD: ""})
      for (let i of data.TWB015) {
        this.useYn1.push(i)
      }

      this.use2Yn1 = data.TWB015;
      this.viewTarget1 = data.TWB004;
      this.btnType1 = data.TWB010
    },

    clickMenuGroupItem(e){
      this.resetMenuDetail();
      this.resetMenuDetailDisable();
      this.resetBtnDetail();
      this.resetBtnDetailDisable();
      this.gridDataList = [];

      this.resetPostData();
      this.URLData = "/api/setting/system/menu-manage/tr/inqire";

      this.headers             = this.initHeaders;
      this.headers["METHOD"]   = "inqire";
      this.headers["ASYNC"]    = false;
      this.headers["IS_ARRAY"] = true;

      this.sendData["MENU_GROUP"] = this.menuGroup;
      this.sendData["USE_YN"]     = this.useYn

      this.result();
    },

    makeTree(data) {
      let getElementIndexNode = (data, i) =>{
        let element = {
          id      : data[i].NODE_ID,
          name    : data[i].NODE_TITLE,
          locked  : data[i].USE_YN !== 'Y',
          cNode   : data[i].C_NODE_NO,
          pNode   : data[i].P_NODE_NO,
          dNode   : data[i].D_NODE_NO,
          nodeInfo: data[i].NODE_ETC,
          nodeType: data[i].NODE_TYPE,
          nodeLvl : data[i].NODE_LVL,
        };
        return element;
      }
      //************************************
      //    all dep treeView for문 구성 중
      //************************************
      let depthLen = 1;
      let lists = [];
      let lists2 = [];

      if( this.mixin_isEmpty(data) ){
        this.common_alert("조회된 데이터가 없습니다", 'error');
        return ;
      }

      if( this.useYn !== "N") {
        // 트리 depth 확인
        for(let i in data) {
          if(Number(data[i].NODE_LVL) > depthLen) {
            depthLen = Number(data[i].NODE_LVL);
          }
        }
        // for문 돌릴 배열 만들기
        for(let j=depthLen; j>0; j--) {
          let tempList = []
          for(let k in data) {
            let element = getElementIndexNode(data, k);
            if(Number(data[k].NODE_LVL) == j) {
              tempList.push(element);
            }
          }
          lists.push(tempList);
          lists2.push(tempList);
        }
        for(let i=0; i<lists.length-1; i++) {
          for(let k in lists[i+1]) {
            for(let j in lists[i]) {
              if(lists[i][j].pNode == lists[i+1][k].cNode) {
                if(lists[i+1][k].nodeType == "D" && !lists2[i+1][k].children) {
                  lists2[i+1][k].children = [];
                  lists2[i+1][k].children.push(lists[i][j]);
                } else if(lists[i+1][k].nodeType == "D" && lists2[i+1][k].children) {
                  lists2[i+1][k].children.push(lists[i][j]);
                }
              }
            }
          }
        }
        this.treeItems = lists2[lists2.length - 1];
      } else {
        // for문 돌릴 배열 만들기
        for (let k in data) {
          lists2.push(getElementIndexNode(data, k));
        }
        this.treeItems = lists2;
      }
    },

    clickMenuGroupItemThen(response){
      let data = response.DATA;
      let items = [];

      if(data.length!=0 && data.at(0).USE_YN == 'Y') {
        for(var i = 0; i < data.length; i++){
          let element = {
            id      : data[i].NODE_ID,
            name    : data[i].NODE_TITLE,
            cNode   : data[i].C_NODE_NO,
            pNode   : data[i].P_NODE_NO,
            dNode   : data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl : data[i].NODE_LVL,
          };
          if(data[i].USE_YN == "N") element["locked"] = true;
          if(data[i].NODE_TYPE == "D") element.children = [];

          if( element.nodeLvl == 2 ){
            for(var j = 0; j < items.length; j++){
              if( element.pNode == items[j].cNode ){
                items[j].children.push(element);
              }
            }
          } else if( element.nodeLvl == 3 ){
            for(var k = 0; k < items.length; k++){
              for(var l = 0; l < items[k].children.length; l++){
                if( element.pNode == items[k].children[l].cNode ){
                  items[k].children[l].children.push(element);
                }
              }
            }
          } else {
            items.push(element);
          }
        }

      }else if(data.length!=0 && data.at(0).USE_YN == 'N'){
        for(var i = 0; i < data.length; i++) {
          let element = {
            id      : data[i].NODE_ID,
            name    : data[i].NODE_TITLE,
            cNode   : data[i].C_NODE_NO,
            pNode   : data[i].P_NODE_NO,
            dNode   : data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl : data[i].NODE_LVL,
            locked  : true,
          };
          if (data[i].NODE_TYPE == "D") {
            element.children = [];
          }
          items.push(element);
        }
      }else{
        this.common_alert("조회된 데이터가 없습니다", 'error');
      }

      this.treeItems = items;
    },

    clickMenuTreeItem(item) {
      if(item.length==0) {return;}
      this.resetBtnDetailDisable();
      this.resetBtnDetail();

      this.selectedTreeItems = [];
      this.selectedTreeItems = item[0];

      this.resetPostData();

      this.URLData = "/api/setting/system/menu-manage/inqire";

      this.headers["SERVICE"] = "setting.system.menu-manage";
      this.headers["METHOD"]  = "inqire";
      this.headers["ASYNC"]   = "alse";
      this.headers["TYPE"]    = "BIZ_SERVICE";

      this.sendData["MENU_ID"] = this.selectedTreeItems.id;

      this.menuDetailNodeType = '';
      this.menuDetailNodeType = this.selectedTreeItems.nodeType;

      this.result();
      this.getBtnList(item[0]);
    },

    clickMenuTreeItemThen(response) {
      let data = response.DATA[0];

      this.menuDetail.id          = data.MENU_ID;
      this.menuDetail.parentId    = data.SPST_MENU_ID;
      this.menuDetail.nodeId      = data.LOWRNK_MENU_ID;
      this.menuDetail.name        = data.MENU_NM;
      this.menuDetail.url         = data.PATH_NM;
      this.menuDetail.param       = data.PGM_PARM;
      this.menuDetail.viewTarget  = data.VIEW_TRGT;
      this.menuDetail.popupWidth  = data.PUP_WIDTH_SIZE;
      this.menuDetail.popupHeight = data.PUP_HGHT_SIZE;
      this.menuDetail.useYn       = data.USE_YN;
      this.menuDetail.sort        = data.SORT_ORD;
      this.menuDetail.memo        = this.restoreXSS_CKeditor(data.MENU_XPLN);
      this.menuDetail.icon        = data.ICON_CLASS_NM;
      this.menuDetail.deptCd      = data.REGR_DEPT_CD;
      this.menuDetail.regDttm     = data.REG_DTTM;
      this.menuDetail.MENU_DIV_CD = data.MENU_DIV_CD;

      this.resetMenuDetailDisable();
      this.changeMenuDetailDisable(data.VIEW_TRGT);
    },

    /**
     * 트리 메뉴 클릭에 따른 메뉴상세-텍스트필드 등  disable
     * @param menuDetail 뷰타겟 객체
     * @return 없음
     * @author
     */
    changeMenuDetailDisable(viewTrgt) {
      this.menuDetailDis.name       = false;
      this.menuDetailDis.useYn      = false;
      this.menuDetailDis.sort       = false;
      this.menuDetailDis.memo       = false;

      if(this.menuDetailNodeType == "D") {
        this.menuDetailDis.nodeId = false;

      } else if (this.menuDetailNodeType == "F") {
        this.menuDetailDis.url        = false;
        this.menuDetailDis.param      = false;
        this.menuDetailDis.viewTarget = false;

      } else if (this.menuDetailNodeType == "N") {
        this.menuDetailDis.nodeId     = false;
        this.menuDetailDis.url        = false;
        this.menuDetailDis.param      = false;
        this.menuDetailDis.viewTarget = false;
      }

      this.clickViewTargetItem(viewTrgt);
    },

    /**
     * 메뉴그룹, 표시여부, 메뉴 클릭에 따른 메뉴상세 disable 초기화
     * @param  없음
     * @return 없음
     * @author
     */
    resetMenuDetailDisable() {
      for(let i in this.menuDetailDis) {
        this.menuDetailDis[i] = true
      }
    },

    /**
     * 메뉴그룹, 표시여부, 메뉴 클릭에 따른 메뉴상세 초기화
     * @param  없음
     * @return 없음
     * @author
     */
    resetMenuDetail() {
      for(let i in this.menuDetail) {
        this.menuDetail[i] = '';
      }
      this.$refs.form.resetValidation();
    },

    /**
     * 뷰타겟 셀렉트박스 클릭에 따른 팝업사이즈 textField disable 유무
     * @param viewTarket 뷰타겟 객체
     * @return 없음
     * @author
     */
    clickViewTargetItem(viewTrgtItem) {
      if(viewTrgtItem == "MENU_POP" || viewTrgtItem == "POP") {
        this.menuDetailDis.popupWidth  = false;
        this.menuDetailDis.popupHeight = false;
      } else {
        this.menuDetailDis.popupWidth  = true;
        this.menuDetailDis.popupHeight = true;
      }
    },

    getBtnList(item) {
      this.resetPostData();
      this.URLData = "/api/setting/system/menu-manage/button/inqire";

      this.headers["SERVICE"] = "setting.system.menu-manage.button";
      this.headers["METHOD"]  = "inqire";
      this.headers["GRID_ID"] = "divGrid";
      this.headers["ASYNC"]   = "false";
      this.headers["TYPE"]    = "BIZ_SERVICE";

      this.sendData["MENU_ID"] = item.id;

      this.result();
    },

    setBtnListThen(response) {
      let data = response.DATA;
      this.gridDataList = [];
      this.btnListData  = [];

      // DB에서 불러온 data에 index가 없어서 임시로 사용
      let idx = 1;
      for (let i in data) {
        let tempObj = data[i]
        tempObj.index = idx++;
        this.gridDataList.push(tempObj);
      }

      // this.btnListData = data;
    },

    clickBtnListRow(item, row) {
      row.select(true);
      this.btnType = item.BTN_TYPE;

      this.btnListDetail.scrNm     = item.SCR_BTN_NM;
      this.btnListDetail.scrId     = item.SCR_BTN_ID;
      this.btnListDetail.btnType   = item.BTN_TYPE;
      this.btnListDetail.btnId     = item.BTN_ID;
      this.btnListDetail.menuId    = item.MENU_ID;
      this.btnListDetail.btnTypeNm = item.BTN_TYPE_NM;

      this.changeBtnDetailDisable();
    },

    clickBtnDetailTypeItem(item) {
      this.btnListDetail.scrId = item.CD;
      this.btnListDetail.scrNm = item.CD_NM;
      this.btnType             = item.CD;
    },

    changeBtnDetailDisable() {
      this.btnDetailDis.btnType = false;
      this.btnDetailDis.btnId   = false;
      this.btnDetailDis.btnNm   = false;
    },

    resetBtnDetailDisable() {
      for(let i in this.btnDetailDis) {
        this.btnDetailDis[i] = true
      }
    },

    resetBtnDetail() {
      for(let i in this.btnListDetail) {
        this.btnListDetail[i] = ''
      }
      this.$refs.form2.resetValidation();
    },

    /**==================================================================
    ====================== button event method ==========================
    ==================================================================**/

    clickAddMenuGroup() {
      this.resetMenuDetail();
      this.resetMenuDetailDisable();

      this.menuDetailNodeType = "D";
      this.menuDetail.parentId = "*";
      this.menuDetail.useYn = "Y";
      this.menuDetail.sort = "1";
      this.menuDetail.viewTarget = "MAIN";
      this.menuDetail.popupWidth = "0";
      this.menuDetail.popupHeight = "0";
      //this.menuDetail.MENU_DIV_CD = "1";

      this.changeMenuDetailDisable("MAIN");
    },

    clickAddMenu() {
      if(this.menuDetail.url.length == 0 && this.menuDetail.MENU_DIV_CD != "03") {
        this.resetMenuDetail();
        this.resetMenuDetailDisable();

        this.menuDetailNodeType = "N"
        this.menuDetail.parentId = this.selectedTreeItems.cNode;
        this.menuDetail.sort = "1";
        this.changeMenuDetailDisable("MAIN");

      } else {
        this.common_alert(this.alertMsg.menuLinkChk, "error");

        return false;
      }
    },

    clickDeleteMenu() {
      this.common_confirm(this.alertMsg.menuDelChk,
          this.delConfirm, null, null, null, "error");
    },

    delConfirm(){
      this.resetPostData();
      this.URLData = "/api/setting/system/menu-manage/delete";

      this.headers["SERVICE"] = "setting.system.menu-manage";
      this.headers["METHOD"] = "delete";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData["LOWRNK_MENU_ID"] = this.selectedTreeItems.cNode;

      this.result();
    },

    clickDeleteMenuThen() {
      this.common_alert(this.alertMsg.succDel, "done");
      this.clickMenuGroupItem();
    },

    clickSaveMenu() {
      if(!this.validate()){
        return;
      }

      // if(this.menuDetail.name.length == 0) {
      //   this.menuHintBool.menuNm = true; this.menuHintHide.menuNm = false; return;}
      // if(this.menuDetail.viewTarget.length == 0) {
      //   this.menuHintBool.viewTarget = true; this.menuHintHide.viewTarget = false; return;}
      // if(this.menuDetail.useYn.length == 0) {
      //   this.menuHintBool.useYn = true; this.menuHintHide.useYn = false; return;}

      this.valMenuId();
    },

    valMenuId() {
      this.resetPostData();
      this.URLData = "/api/setting/system/menu-manage/id-dplct-ceck/inqire";

      this.headers["SERVICE"] = "setting.system.menu-manage.id-dplct-ceck";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData["MENU_ID"] = this.menuDetail.id;
      this.sendData["MENU_DIV_CD"] = this.selectedTreeItems.dNode;
      this.sendData["MENU_ID_INFO"] = this.selectedTreeItems.nodeInfo;
      this.sendData["SPST_MENU_ID"] = this.menuDetail.parentId;
      this.sendData["LOWRNK_MENU_ID"] = this.menuDetail.nodeId;
      this.sendData["MENU_NM"] = this.menuDetail.name;
      this.sendData["PATH_NM"] = this.menuDetail.url;
      this.sendData["PGM_PARM"] = this.menuDetail.param;
      this.sendData["ICON_CLASS_NM"] = this.menuDetail.icon;
      this.sendData["VIEW_TRGT"] = this.menuDetail.viewTarget;
      this.sendData["PUP_WIDTH_SIZE"] = this.menuDetail.popupWidth;
      this.sendData["PUP_HGHT_SIZE"] = this.menuDetail.popupHeight;
      this.sendData["USE_YN"] = this.menuDetail.useYn;
      this.sendData["SORT_ORD"] = this.menuDetail.sort;
      this.sendData["MENU_XPLN"] = this.restoreXSS_CKeditorDec(this.menuDetail.memo);

      this.result();
    },

    valMenuIdThen(data) {
      if(data.HEADER.ERROR_FLAG == true) {
        this.common_alert(this.alertMsg.err, "error");
        return;
      }

      let valChk = data.DATA;
      if(valChk.length != 0) {
        if(this.menuDetail.id=='' || this.menuDetail.id==null) {
          let msg ="중복된 ID값이 존재합니다!<br>(메뉴명:" + valChk.at().MENU_NM + ")ID를 변경하시기 바랍니다.";
          this.common_alert(msg, "error");
          return;
        } else {
          this.common_confirm(this.alertMsg.modChk,
              this.setUpdateMenu, null, null, null, "chk");
        }
      } else {
        if(this.menuDetail.id=='' || this.menuDetail.id==null) {
          this.getIdUniqKey("BIZ");
        }
      }
    },

    // 신규 메뉴 추가
    setInsertMenu(idKey) {
      this.resetPostData();
      this.URLData = "/api/setting/system/menu-manage/regist";

      this.headers["SERVICE"] = "setting.system.menu-manage";
      this.headers["METHOD"] = "regist";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData["MENU_ID"] = idKey.RET_VAL;
      this.sendData["MENU_DIV_CD"] = `0${Number(this.selectedTreeItems.dNode) + 1}`;
      this.sendData["MENU_ID_INFO"] = this.selectedTreeItems.nodeInfo;
      this.sendData["SPST_MENU_ID"] = this.menuDetail.parentId;
      this.sendData["LOWRNK_MENU_ID"] = this.menuDetail.nodeId;
      this.sendData["MENU_NM"] = this.menuDetail.name;
      this.sendData["PATH_NM"] = this.menuDetail.url;
      this.sendData["PGM_PARM"] = this.menuDetail.param;
      this.sendData["ICON_CLASS_NM"] = this.menuDetail.icon;
      this.sendData["VIEW_TRGT"] = this.menuDetail.viewTarget;
      this.sendData["PUP_WIDTH_SIZE"] = this.menuDetail.popupWidth;
      this.sendData["PUP_HGHT_SIZE"] = this.menuDetail.popupHeight;
      this.sendData["USE_YN"] = this.menuDetail.useYn;
      this.sendData["SORT_ORD"] = this.menuDetail.sort;
      this.sendData["MENU_XPLN"] = this.restoreXSS_CKeditorDec(this.menuDetail.memo);
      this.sendData["DATA_FLAG"] = 'I';
      this.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.sendData["REGR_DEPT_CD"] = this.menuDetail.deptCd;
      this.sendData["REGR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.sendData["REG_DTTM"] = this.menuDetail.regDttm;

      this.result();
    },

    // 기존 메뉴 수정 후 저장
    setUpdateMenu() {
      this.URLData = "/api/setting/system/menu-manage/regist"

      this.headers["SERVICE"] = "setting.system.menu-manage";
      this.headers["METHOD"] = "regist";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      let tempData = [];
      let tempObj = {};

      let sendDataPush = (key, value) => {
        let tempData = [];
        let tempObj = {};
        tempObj[key] = value;
        tempData.push(tempObj);
        tempData.push(tempObj);
        return tempData;
      }

      this.sendData["MENU_ID"]        = sendDataPush("MENU_ID", this.menuDetail.id);
      this.sendData["MENU_DIV_CD"]    = sendDataPush("MENU_DIV_CD", this.selectedTreeItems.dNode);
      this.sendData["MENU_ID_INFO"]   = sendDataPush("MENU_ID_INFO", this.selectedTreeItems.nodeInfo);
      this.sendData["SPST_MENU_ID"]   = sendDataPush("SPST_MENU_ID", this.menuDetail.parentId);
      this.sendData["LOWRNK_MENU_ID"] = sendDataPush("LOWRNK_MENU_ID", this.menuDetail.nodeId);
      this.sendData["MENU_NM"]        = sendDataPush("MENU_NM", this.menuDetail.name);
      this.sendData["PATH_NM"]        = sendDataPush("PATH_NM", this.menuDetail.url);
      this.sendData["PGM_PARM"]       = sendDataPush("PGM_PARM", this.menuDetail.param);
      this.sendData["ICON_CLASS_NM"]  = sendDataPush("ICON_CLASS_NM", this.menuDetail.icon);
      this.sendData["VIEW_TRGT"]      = sendDataPush("VIEW_TRGT", this.menuDetail.viewTarget);
      this.sendData["PUP_WIDTH_SIZE"] = sendDataPush("PUP_WIDTH_SIZE", this.menuDetail.popupWidth);
      this.sendData["PUP_HGHT_SIZE"]  = sendDataPush("PUP_HGHT_SIZE", this.menuDetail.popupHeight);
      this.sendData["USE_YN"]         = sendDataPush("USE_YN", this.menuDetail.useYn);
      this.sendData["SORT_ORD"]       = sendDataPush("SORT_ORD", this.menuDetail.sort);
      this.sendData["MENU_XPLN"]      = sendDataPush("MENU_XPLN", this.restoreXSS_CKeditorDec(this.menuDetail.memo));
      this.sendData["PROC_ID"]        = sendDataPush("PROC_ID", this.$store.getters['userStore/GE_USER_ROLE'].userId);
      this.sendData["AMDR_DEPT_CD"]   = sendDataPush("AMDR_DEPT_CD", "x");
      this.sendData["AMDR_ID"]        = sendDataPush("AMDR_ID", this.$store.getters['userStore/GE_USER_ROLE'].userId);

      tempObj["DATA_FLAG"] = "U";
      tempData.push(tempObj);
      tempObj = {};
      tempObj["DATA_FLAG"] = "YN";
      tempData.push(tempObj);
      tempObj = {};
      this.sendData["DATA_FLAG"] = tempData;
      tempData = [];

      tempObj["UPD_DTTM"] = "SYSDATE";
      tempData.push(tempObj);
      this.sendData["UPD_DTTM"] = tempData;

      this.result();
    },

    clickSaveMenuThen() {
      this.common_alert(this.alertMsg.succ, "done");
      this.clickMenuGroupItem();
    },

    clickAddBtn() {
      if(this.menuDetail.id == null || this.menuDetail.id == '') {
        this.common_alert(this.alertMsg.btnAddChk, "error");
        return;
      }
      this.resetBtnDetail();
      this.changeBtnDetailDisable();
      this.btnListDetail.menuId = this.menuDetail.id
    },

    clickDeleteBtn() {
      if(this.btnListDetail.menuId == null || this.btnListDetail.menuId == '') {
        this.common_alert(this.alertMsg.btnDelChk, "error");
        return false;
      }

      this.common_confirm(this.alertMsg.delChk,
          this.delConfirmBtn, null, null, null, "error");
    },

    delConfirmBtn(){
      this.resetPostData();

      this.URLData = "/api/setting/system/menu-manage/button/delete";

      this.headers["SERVICE"] =  "setting.system.menu-manage.button";
      this.headers["METHOD"] = "delete";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData["BTN_ID"] = this.btnListDetail.btnId;

      this.result();
    },

    clickDeleteBtnThen() {
      this.common_alert(this.alertMsg.succDel, "done");
      this.$refs.form2.resetValidation();
      this.clickMenuTreeItem(this.tempTreeItems());
    },

    clickSaveBtn() {
      if(!this.validateBtn()){
        return;
      }
      // if(this.btnListDetail.btnType.length == 0) {
      //   this.btnHintBool.btnType = true; this.btnHintHide.btnType = false; return;}
      // if(this.btnListDetail.scrId.length == 0) {
      //   this.btnHintBool.btnId = true; this.btnHintHide.btnId = false; return;}
      // if(this.btnListDetail.scrNm.length == 0) {
      //   this.btnHintBool.btnNm = true; this.btnHintHide.btnNm = false; return;}

      if(!this.btnListDetail.btnId) {
        this.getIdUniqKey("BTN");
      } else {
        this.setModifyBtn();
      }
    },

    setInsertBtn(idKey) {
      this.resetPostData();
      this.URLData = "/api/setting/system/menu-manage/button/regist";

      this.headers["SERVICE"] = "setting.system.menu-manage.button";
      this.headers["METHOD"] = "regist";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData["BTN_ID"] = idKey.RET_VAL;
      this.sendData["MENU_ID"] = this.menuDetail.id;
      this.sendData["BTN_TYPE"] = this.btnType;
      this.sendData["SCR_BTN_ID"] = this.btnListDetail.scrId;
      this.sendData["SCR_BTN_NM"] = this.btnListDetail.scrNm;
      this.sendData["REGR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;

      this.result();
    },

    setModifyBtn() {
      this.resetPostData();
      this.URLData = "/api/setting/system/menu-manage/button/modify";

      this.headers["SERVICE"] = "setting.system.menu-manage.button";
      this.headers["METHOD"] = "modify";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData["BTN_ID"] = this.btnListDetail.btnId;
      this.sendData["MENU_ID"] = this.menuDetail.id;
      this.sendData["BTN_TYPE"] = this.btnType;
      this.sendData["SCR_BTN_ID"] = this.btnListDetail.scrId;
      this.sendData["SCR_BTN_NM"] = this.btnListDetail.scrNm;
      this.sendData["AMDR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;;

      this.result();
    },

    clickSaveBtnThen() {
      this.common_alert(this.alertMsg.succ, "done");
      this.$refs.form2.resetValidation();
      this.clickMenuTreeItem(this.tempTreeItems());
    },

    /**==================================================================
     =========================== common method ==========================
     ==================================================================**/

    getIdUniqKey(bizCase) {
      this.resetPostData();
      this.URLData = "/api/innb/common/generate-unique-no/inqry";

      this.headers["SERVICE"] = "innb.common.generate-unique-no.inqry."+bizCase;
      this.headers["METHOD"] = "inqry";
      this.headers["ASYNC"] = "false";
      this.headers["TYPE"] = "BIZ_SERVICE";

      this.sendData["BIZ_CASE"] = bizCase;

      this.result();
    },

    resetPostData() {
      this.URLData = '';
      this.headers = {};
      this.sendData = {};
    },

    validate () {
      return this.$refs.form.validate();
    },

    validateBtn () {
      return this.$refs.form2.validate();
    },

    async result() {
      console.log("run result~");

      let headR = '';
      let dataR = '';

      //결과값을 보여주는 부분 -> 실제 사용 시 dataR의 value를 사용

      //api 호출 부분
      await api.post(this.URLData,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          this.sendData
          , //api에서 사용될 data 입력
          {
            head: this.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            //파라미터검증 실패 시 alert
            if(response.data.HEADER.ERROR_FLAG == true) {
              this.common_alert(this.alertMsg.err, "error")
              return;
            }

            switch (response.data.HEADER.URL) {
              case "/api/setting/system/menu-manage/group/inqire":
                this.getMenuGroupThen(response.data)
                break;

              case "/api/code/common/code-book/inqry":
                this.getSelBoxListThen(response.data);
                break;

              case "/api/setting/system/menu-manage/tr/inqire":
                //this.clickMenuGroupItemThen(response.data);
                this.makeTree(response.data.DATA);
                break;

              case "/api/setting/system/menu-manage/inqire":
                this.clickMenuTreeItemThen(response.data);
                break;

              case "/api/setting/system/menu-manage/button/inqire":
                this.setBtnListThen(response.data);
                break;

              case "/api/innb/common/generate-unique-no/inqry":
                if(response.data.HEADER.SERVICE == "innb.common.generate-unique-no.inqry.BIZ") {
                  this.setInsertMenu(response.data.DATA.at());
                } else if(response.data.HEADER.SERVICE == "innb.common.generate-unique-no.inqry.BTN") {
                  this.setInsertBtn(response.data.DATA.at());
                }
                break;
              case "/api/setting/system/menu-manage/regist":
                this.clickSaveMenuThen();
                break;

              case "/api/setting/system/menu-manage/delete":
                this.clickDeleteMenuThen();
                break;

              case "/api/setting/system/menu-manage/id-dplct-ceck/inqire":
                this.valMenuIdThen(response.data);
                break;

              case "/api/setting/system/menu-manage/button/regist":
                this.clickSaveBtnThen();
                break;

              case "/api/setting/system/menu-manage/button/modify":
                this.clickSaveBtnThen();
                break;

              case "/api/setting/system/menu-manage/button/delete":
                this.clickDeleteBtnThen();
                break;

              default:
                break;
            }

            headR = JSON.stringify(response.data.HEADER);
            dataR = JSON.stringify(response.data.DATA);

          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행

      this.HEADERResult = headR;
      this.DATAResult = dataR;

      if(this.URLData=="/api/setting/system/menu-manage/tr/inqire") {
        //트리메뉴 펼치기
        this.unfoldTreeview();
      }
    },
  },

  mounted() {
    console.log("init~");

    this.getMenuGroup();
    this.getSelBoxList();
  },

  computed: {
    initHeaders(){
      return this.headers = {
        "SERVICE" : this.HEADER_SERVICE,
        "METHOD" : "",
        "TYPE" : this.HEADER_TYPE,
      };
    },
  },
};
</script>

<style></style>
